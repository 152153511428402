/**
 * @fileoverview This file houses functions that can be used for
 * validation of form fields.
 *
 * Note that these functions should return a string error message
 * when they fail, and `undefined` when they pass.
 */

const SUCCESS = undefined;

export const hasLengthAtLeast = (value, values, minimumLength) => {
    if (!value || value.length < minimumLength) {
        return `Das Feld muss mindestens ${minimumLength} zeichen enthalten.`;
    }

    return SUCCESS;
};

export const hasLengthAtMost = (value, values, maximumLength) => {
    if (value && value.length > maximumLength) {
        return `Das Feld darf die Anzahl von ${maximumLength} zeichen nicht überschreiten.`;
    }

    return SUCCESS;
};

export const hasLengthExactly = (value, values, length) => {
    if (value && value.length !== length) {
        return `Das Feld muss ${length} Zeichen enthalten.`;
    }

    return SUCCESS;
};

export const isChecked = (value) => {
    return value || ''
        ? SUCCESS
        : 'Das Kontrollkästchen muss angeklickt werden.';
};
export const isBiggerThanOrEqual = (value, values, min) => {
    return value && value >= min
        ? SUCCESS
        : 'Der Wert muss größer oder gleich ' + min + ' Euro sein';
};
export const isRequired = (value) => {
    return value || '' ? SUCCESS : 'Dies ist ein Pflichtfeld.';
};
export const branchIsRequired = (value) => {
    return value || '' ? SUCCESS : 'Bitte wählen Sie Ihre Filiale';
};
export const validateEmail = (value) => {
    const regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(value)
        ? SUCCESS
        : 'Bitte geben Sie eine gültige E-Mail-Adresse an (z.B. johndoe@domain.com).';
};

export const onlyNumbersAllowed = (value) => {
    return value == undefined || !isNaN(value)
        ? SUCCESS
        : 'Hier sind nur Zahlen erlaubt.';
};

export const forbiddenChars = (value) => {
    const listOfChars = ['^', '[', ']', '>', '<'];
    const valuesArr = value ? value.split('') : [];
    let errMsg = null;
    listOfChars.forEach((forbiddenChar) => {
        if (valuesArr.includes(forbiddenChar) && errMsg === null) {
            errMsg = `Folgende Zeichen sind nicht erlaubt: (${listOfChars.join(
                ', '
            )})`;
        }
    });
    return errMsg ? errMsg : SUCCESS;
};

export const validateRegionCode = (value, values, countries) => {
    const country = countries.find(({ id }) => id === 'US');

    if (!country) {
        return 'Das Land "US" ist ungültig.';
    }
    const { available_regions: regions } = country;

    if (!(Array.isArray(regions) && regions.length)) {
        return 'Das angegebene Land „US“ enthält keine verfügbaren Regionen.';
    }

    const region = regions.find(({ code }) => code === value);
    if (!region) {
        return `Das Land "${value}" ist keine gültige staatliche Abkürzung.`;
    }

    return SUCCESS;
};

export const validatePassword = (value) => {
    const count = {
        lower: 0,
        upper: 0,
        digit: 0,
        special: 0
    };

    for (const char of value) {
        if (/[a-z]/.test(char)) count.lower++;
        else if (/[A-Z]/.test(char)) count.upper++;
        else if (/\d/.test(char)) count.digit++;
        else if (/\S/.test(char)) count.special++;
    }

    if (Object.values(count).filter(Boolean).length < 3) {
        return 'Das Passwort muss mindestens drei der folgenden Zeichen enthalten: Kleinbuchstaben, Großbuchstaben, Zahlen, Sonderzeichen.';
    }

    return SUCCESS;
};

export const validateConfirmPassword = (
    value,
    values,
    passwordKey = 'password'
) => {
    return value === values[passwordKey]
        ? SUCCESS
        : 'Das Passwort muss übereinstimmen.';
};

export const validateConfirmPhone = (value, values, phoneKey = 'phone') => {
    return value === values[phoneKey]
        ? SUCCESS
        : 'Das Telefonnummer muss übereinstimmen.';
};
