import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
    setUserDetails,
    setLoadingUserDetails,
    setErrorUserDetails
} from '../../store/actions/user';
import {
    createCartDetails,
    createCartState,
    resetCartState
} from '../../store/actions/cart';
import { setWishlistMode, addToWishlist } from '../../store/actions/wishlist';
import { redirectTo } from '../../util/redirect';
import GET_PRODUCTS_BY_SKU from '../../queries/getProductsBySku.graphql';
import { useCookies } from 'react-cookie';
import ADD_TO_WISH_LIST from '../../queries/addProductsToWishlist.graphql';
import GET_CUSTOMER_CART from '../../queries/getCustomerCart.graphql';
import MERGE_CARTS from '../../queries/mergeCarts.graphql';
import GET_CART_DETAILS from '../../queries/getCartDetails.graphql';

export const useSignIn = (props) => {
    const {
        signInMutation,
        getUserDetailsQuery,
        preparedItems,
        guestCartId,
        checkout
    } = props;
    const cart = useSelector((state) => state.cart);
    const existingGuestWishlist = JSON.parse(
        localStorage.getItem('guestWishlist')
    )
        ? JSON.parse(localStorage.getItem('guestWishlist'))
        : [];
    const dispatch = useDispatch();
    const handleUserDetails = (payload) => dispatch(setUserDetails(payload));
    const handleErrorUserDetails = (payload) =>
        dispatch(setErrorUserDetails(payload));
    const handleLoadingUserDetails = (payload) =>
        dispatch(setLoadingUserDetails(payload));
    const handleWishlistMode = (payload) => dispatch(setWishlistMode(payload));
    const handleAddToWishlist = (payload) => dispatch(addToWishlist(payload));
    const [, setCookie] = useCookies(['authToken']);

    const router = useRouter();

    const [signIn, { data: signInData, loading, error: signInError }] =
        useMutation(signInMutation, {
            fetchPolicy: 'no-cache',
            onCompleted: (data) => {
                localStorage.setItem(
                    'signin_token',
                    data.generateCustomerToken.token
                );
                getUserDetails();
            },
            onError: (error) => {
                console.log(error);
            }
        });

    const [
        getCartDetails,
        {
            data: cartDetailsData,
            error: cartDetailsError,
            loading: cartDetailsLoading
        }
    ] = useLazyQuery(GET_CART_DETAILS, {
        onCompleted: (data) => {
            data?.cart?.items?.length > 0
                ? dispatch(createCartDetails(data.cart))
                : null;
            checkout
                ? router.reload()
                : redirectTo(router, '/account/overview');
        },
        onError: (error) => {
            if (error) {
                dispatch(resetCartState());
            }
        },
        context: {
            headers: {
                authorization: `Bearer ${localStorage.getItem('signin_token')}`
            }
        }
    });

    const [mergeCarts] = useMutation(MERGE_CARTS, {
        context: {
            headers: {
                authorization: `Bearer ${localStorage.getItem('signin_token')}`
            }
        },
        onCompleted: async (data) => {
            await dispatch(
                createCartState({ ...cart, cartId: data.mergeCarts.id })
            );
            await dispatch(createCartDetails(data?.mergeCarts));
            checkout
                ? router.reload()
                : redirectTo(router, '/account/overview');
        }
    });

    const [getCustomerCart, { data: customerCartData }] = useLazyQuery(
        GET_CUSTOMER_CART,
        {
            onCompleted: async (data) => {
                const customerCartId = data?.customerCart?.id;
                if (
                    guestCartId &&
                    customerCartId &&
                    customerCartId != guestCartId
                ) {
                    await mergeCarts({
                        variables: {
                            guestCartId: guestCartId,
                            customerCartId: customerCartId
                        },
                        fetchPolicy: 'no-cache'
                    });
                } else {
                    await dispatch(
                        createCartState({ ...cart, cartId: customerCartId })
                    );
                    await getCartDetails({
                        variables: {
                            cartId: customerCartId
                        }
                    });
                }
            },
            onError: (er) => {
                console.log('ER: ', er);
            },
            context: {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        'signin_token'
                    )}`
                }
            }
        }
    );

    const [addToWishList, {}] = useMutation(ADD_TO_WISH_LIST, {
        onCompleted: (data) => handleAddToWishlist(data.addProductsToWishlist),
        context: {
            headers: {
                authorization: `Bearer ${localStorage.getItem('signin_token')}`
            }
        }
    });

    const [
        getUserDetails,
        { loading: loadingUserDetails, error: errorUserDetails }
    ] = useLazyQuery(getUserDetailsQuery, {
        onCompleted: (data) => {
            handleUserDetails(data.customer);
            handleLoadingUserDetails(false);
            setCookie('authToken', localStorage.getItem('signin_token'), {
                maxAge: 3540,
                secure: false,
                sameSite: 'Strict'
            });
            handleWishlistMode(false);
            if (existingGuestWishlist && preparedItems.length > 0) {
                addToWishList({
                    variables: {
                        wishlistId: data.customer.wishlist.id,
                        wishlistItems: preparedItems
                    }
                });
            } else {
                handleAddToWishlist({ wishlist: data.customer.wishlist });
            }
            localStorage.removeItem('guestWishlist');
            getCustomerCart();
        },
        onError: (error) => {
            handleErrorUserDetails(error);
            handleWishlistMode(true);
        },
        context: {
            headers: {
                authorization: `Bearer ${localStorage.getItem('signin_token')}`
            }
        }
    });
    const handleSubmit = ({ email, password }) => {
        handleLoadingUserDetails(true);
        signIn({
            variables: { email, password }
        });
    };

    return {
        handleSubmit,
        signInData,
        signInLoading: loading || loadingUserDetails ? true : false,
        signInError,
        errorUserDetails
    };
};
